<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card>
          <v-card-title class="elevation-1">
            Edit Affiliator
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="profileForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="nameRules"
                      v-model="firstname"
                      label="First Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="nameRules"
                      v-model="lastname"
                      label="Last Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="phoneRules"
                      v-model="phone"
                      label="Phone Number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="whatsapp"
                      label="Whatsapp Number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      :rules="emailRules"
                      v-model="email"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="district"
                      label="District"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="locality"
                      label="Locality"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="city" label="City"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="streetAddress"
                      label="Street Address"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      :rules="pinRules"
                      v-model="pincode"
                      label="Pincode"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      v-model="affliator.category"
                      label="Category"
                      :items="catArray"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      v-model="affliator.areaOfInterest"
                      label="Area Of Interest"
                      :items="options"
                      multiple
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row py-2>
                  <v-col cols="12">Social media links</v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      color="#FF1313"
                      label="Facebook URL"
                      dense
                      v-model="facebook"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      color="#FF1313"
                      label="Instagram URL"
                      dense
                      v-model="instagram"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      color="#FF1313"
                      label="Twitter URL"
                      dense
                      v-model="twitter"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      color="#FF1313"
                      label="Pinterest URL"
                      dense
                      v-model="pinterest"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-row class="justify-center">
                    <v-col cols="10">
                      <v-img
                        v-if="user.image && !imagePreview"
                        :src="mediaURL + user.image"
                        width="200px"
                        height="150px"
                        style="align-center"
                        contain
                      />
                      <img
                        v-if="imagePreview"
                        :src="imagePreview"
                        v-show="showPreview"
                        width="200px"
                        height="150px"
                        class="align-center"
                        contain
                      />
                    </v-col>

                    <v-row class="justify-center">
                      <v-col cols="10">
                        <v-dialog v-model="changeImageDialog" max-width="800px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn outlined v-on="on" v-bind="attrs">
                              Update Profile Picture
                            </v-btn>
                          </template>

                          <v-card>
                            <v-row class="py-5">
                              <v-col cols="12">
                                <ImageComp
                                  :singleImage="imagePreview"
                                  @stepper="winStepper"
                                  :height="'540'"
                                  :width="'540'"
                                  :heading="'Upload  Image (540 X 540)'"
                                  :componentType="'image'"
                                />
                                <!-- (1195 X 804) -->
                              </v-col>
                              <br />
                              <v-col class="text-center" cols="12">
                                <v-btn
                                  :disabled="!file"
                                  color="green"
                                  @click="uploadImage"
                                >
                                  <span style="color: white"> submit </span>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-row>
                  <!-- <v-row class="justify-center">
                    <v-col cols="10">
                      <v-img
                        v-if="user.affliator.portifolio && !imagePreview1"
                        :src="mediaURL + user.affliator.portifolio"
                        width="200px"
                        height="150px"
                        style="align-center"
                        contain
                      />
                      <img
                        v-if="imagePreview1"
                        :src="imagePreview1"
                        v-show="showPreview1"
                        width="200px"
                        height="150px"
                        class="align-center"
                        contain
                      />
                    </v-col>

                    <v-row class="justify-center">
                      <v-col cols="10">
                        <v-btn
                          outlined
                          class="text-capitalize"
                          color="#000000"
                          @click="$refs.showImage1.click()"
                        >
                          Update Protfolio
                        </v-btn>
                        <input
                          v-show="false"
                          accept="image/*"
                          id="file"
                          ref="showImage1"
                          multiple="multiple"
                          type="file"
                          @change="showImage1"
                        />
                      </v-col>
                    </v-row>
                  </v-row> -->
                </v-row>
                <v-row class="justify-center" style="padding-top: 80px">
                  <v-btn
                    :disabled="!valid"
                    outlined
                    color="red"
                    @click="validate()"
                  >
                    Update
                  </v-btn>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      changeImageDialog: false,
      changeCoverImageDialog: false,
      ServerError: false,
      coverPreview: "",
      showPreview: false,
      showPreview1: false,
      showcoverPreview: false,
      bank: [],
      file: null,
      file1: null,
      coverFile: null,
      image: "",
      coverimage: "",
      imagePreview: "",
      imagePreview1: "",
      appLoading: false,
      showsnackbar: false,
      firstname: null,
      facebook: null,
      instagram: null,
      twitter: "",
      pinterest: "",
      lastname: null,
      ownername: null,
      username: null,
      accounttype: null,
      country: null,
      phone: null,
      whatsapp: null,
      pincode: null,
      email: null,
      district: null,
      locality: null,
      city: null,
      organization: null,
      streetAddress: null,
      bankname: null,
      accountholder: null,
      accountnumber: null,
      branch: null,
      ifsccode: null,
      gstid: null,
      type: null,
      catArray: ["Fashion Designers", "Fashion Models", "Fashion Stylist", "Fashion Influencers"],
      options: [
        "Personal Shopping",
        "Personal Styling",
        "Fashion Blogging",
        "Color Analysis",
        "Image Consulting",
      ],
      affliator: {
        category: "",
        areaOfInterest: [],
      },
      commisionPercentage: null,
      newProductsOrder: "",
      offersOrder: "",
      galleryOrder: "",
      msg: null,
      valid: true,
      user: [],
      seller: [],
      Rules: [(v) => !!v || "Required"],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 15) || "Name must be less than 15 characters",
      ],
      emailRules: [
        (v) => !!v || "Email is Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Phone Number is Required",
        (v) => (v && v.length <= 10) || "Number must be less than 11",
        (v) => (v && v.length >= 10) || "Number must be grater than 10",
        (v) => /^\d{10}$/.test(v) || "Phone must be number",
      ],
      pinRules: [
        (v) => !!v || "Pincode is Required",
        (v) => /^(\d{4}|\d{6})$/.test(v) || "Pincode must be valid",
      ],
    };
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "image") {
        this.file = window_data.selectedFiles;
      } else if (window_data.type == "coverImage") {
        this.coverFile = window_data.selectedFiles;
      }
    },
    validate() {
      if (this.$refs.profileForm.validate()) {
        this.editUser();
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/affliator/info",
        method: "GET",
        params: {
          id: this.$route.query.affiliateId,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.seller = this.user.seller;
          this.firstname = this.user.firstname;
          this.lastname = this.user.lastname;
          this.ownername = this.user.seller.ownername;
          this.username = this.user.username;
          this.phone = this.user.phone;
          this.email = this.user.email;
          this.district = this.user.district;
          this.pincode = this.user.pincode;
          this.locality = this.user.locality;
          this.city = this.user.city;
          this.organization = this.user.organization;
          this.streetAddress = this.user.streetAddress;
          this.whatsapp = this.user.whatsapp;
          this.facebook = this.user.facebook;
          this.instagram = this.user.instagram;
          this.twitter = this.user.twitter;
          this.pinterest = this.user.pinterest;
          this.affliator.category = this.user.affliator.category;
          this.type = this.user.type;
          this.accounttype = this.user.accounttype;
          this.country = this.user.country;
          this.affliator.areaOfInterest = this.user.affliator.areaOfInterest;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editUser() {
      this.appLoading = true;
      var user = {};
      user["affliator"] = true;
      user["firstname"] = this.firstname;
      user["lastname"] = this.lastname;
      user["phone"] = this.phone;
      user["email"] = this.email;
      user["streetAddress"] = this.streetAddress;
      user["pincode"] = this.pincode;
      user["locality"] = this.locality;
      user["district"] = this.district;
      user["city"] = this.city;
      user["whatsapp"] = this.whatsapp;
      user["facebook"] = this.facebook;
      user["instagram"] = this.instagram;
      user["twitter"] = this.twitter;
      user["pinterest"] = this.pinterest;
      user["category"] = this.affliator.category;
      user["areaOfInterest"] = this.affliator.areaOfInterest;
      user["id"] = this.$route.query.affiliateId;
      axios({
        method: "POST",
        url: "/user/AdminEdit",

        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.imagePreview) {
              this.uploadImage();
            }
            // if (this.imagePreview1) {
            //   this.uploadPortfolio();
            // } 
            else {
              this.getData();
              // this.uploadImage();
              this.$router.go(-1);
            }
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    showImage(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      if (this.file.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showsnackbar = true;
        return;
      }
      this.showPreview = true;
      //reader.readAsDataURL(files[0]);
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    uploadImage() {
      let formData = new FormData();
      formData.append("photos", this.file);
      formData.append("id", this.$route.query.affiliateId);
      axios({
        method: "POST",
        url: "/User/adminUploadImage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.appLoading = false;
            this.changeImageDialog = false;
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.getData();

            // this.$router.go(-1);
          } else {
            this.appLoading = false;

            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    showImage1(e) {
      this.file1 = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview1 = e.target.result;
      };
      if (this.file1.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showsnackbar = true;
        return;
      }
      this.showPreview1 = true;
      //reader.readAsDataURL(files[0]);
      if (this.file1) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file1.name)) {
          reader.readAsDataURL(this.file1);
        }
      }
    },
    uploadPortfolio() {
      let formData = new FormData();
      formData.append("photos", this.file1);
      axios({
        method: "POST",
        url: "/affliator/UploadPortifolio",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.appLoading = false;
            this.changeImageDialog = false;
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.getData();
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
